import React, { Fragment, useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import {
  ChangeMaterialsFiltersFunc,
  FetchMaterialsLimit,
  FetchMaterialsSort
} from '../../../materialsTypes';
import { SelectMaterialsContentOnMaterialsSelect } from './SelectMaterialsContent.types';

import {
  FETCH_MATERIALS_QUERY,
  FetchMaterialsQueryResponse
} from '../../../queries/fetchMaterials.query';
import {
  FETCH_MATERIALS_SET_BY_USER_ID_QUERY,
  FetchMaterialsSetByUserIdQueryResponse
} from '../../../../materialsSets/queries/fetchMaterialsSetByUserId.query';
import { TogglePreventModalCloseAction } from '../../../../../helpers/modals/modalsTypes';
import { FetchMaterialsSetByUserIdScopeType } from '../../../../materialsSets/materialsSetsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useMainScroll } from '../../../../../app/hooks/useMainScroll';

import { useMaterialsSetByUserId } from '../../../../materialsSets/hooks/useMaterialsSetByUserId';
import { useMaterials } from '../../../hooks/useMaterials';

import { SelectedMaterialsFloatingCounter } from '../../../../selectedMaterials/components/SelectedMaterialsFloatingCounter';
import { MaterialsList } from '../../lists/MaterialsList';
import { SelectMaterialsContentFiltersSidebar } from './components/SelectMaterialsContentFiltersSidebar';
import { SelectMaterialsContentHeader } from './components/SelectMaterialsContentHeader';
import { SelectMaterialsContentSidebar } from './components/SelectMaterialsContentSidebar';

import { MainScrollWrapper } from '../../../../common/helpers/MainScrollWrapper';
import { LoadMoreButtonHelper } from '../../../../../helpers/buttons/LoadMoreButtonHelper';
import { NoResults } from '../../../../../helpers/NoResults';

import { MaterialsSetCache } from '../../../../materialsSets/MaterialsSetCache';
import { MaterialCache } from '../../../MaterialCache';
import { MaterialsPermissions } from '../../../materialsConstants';

import { words } from '../../../../../locales/keys';

const materialsCacheKey = MaterialCache.indexCacheKey();
const materialsCacheKeys = [materialsCacheKey];
const materialsSetCacheKey = MaterialsSetCache.showCacheKey();
const materialsSetCacheKeys = [materialsSetCacheKey];

interface SelectMaterialsContentProps {
  initialLimit: FetchMaterialsLimit;
  onMaterialsSelect: SelectMaterialsContentOnMaterialsSelect;
  togglePreventModalClose: TogglePreventModalCloseAction;
  materialsSetScope?: FetchMaterialsSetByUserIdScopeType;
}

function SelectMaterialsContent({
  initialLimit,
  onMaterialsSelect,
  togglePreventModalClose,
  materialsSetScope
}: SelectMaterialsContentProps) {
  const {
    materials,
    materialsError,
    materialsTotalCount,
    materialsFetched,
    materialsFetchingNextPage,
    materialsFilters,
    materialsIsPlaceholderData,
    hasNextMaterialsPage,
    updateMaterialCache,
    loadMoreMaterials,
    changeMaterialsFilters,
    clearMaterialsFiltersPersistInitial,
    sortMaterials,
    materialsSort,
    filterMaterials,
    materialsPage,
    materialsLimit
  } = useMaterials<FetchMaterialsQueryResponse>({
    cacheKey: materialsCacheKey,
    query: FETCH_MATERIALS_QUERY,
    initialLimit,
    initialFilters: {
      nda: false
    }
  });

  const currentUser = useCurrentUser();

  const {
    materialsSet,
    materialsSetError,
    materialsSetFetched,
    materialsSetIsPlaceholderData,
    updateMaterialsSetCache
  } = useMaterialsSetByUserId<FetchMaterialsSetByUserIdQueryResponse>({
    cacheKey: MaterialsSetCache.showCacheKey(),
    query: FETCH_MATERIALS_SET_BY_USER_ID_QUERY,
    userId: currentUser.get('id'),
    scope: materialsSetScope,
    options: {
      onSuccess: (data) =>
        data?.materialsSetByUserId?.selectedMaterials
          ? onMaterialsSelect?.(data?.materialsSetByUserId?.selectedMaterials)
          : undefined
    }
  });

  const { scrollTop } = useMainScroll();

  const handleChangeMaterialsFilters = useCallback<ChangeMaterialsFiltersFunc>(
    (changedFilters, removeFilters) => {
      scrollTop();

      return changeMaterialsFilters(changedFilters, removeFilters);
    },
    [changeMaterialsFilters, scrollTop]
  );

  const [isSelectedMaterialsSidebarOpen, setIsSelectedMaterialsSidebarOpen] =
    useState(false);

  const handleSelectedMaterialsSidebarOpen = useCallback<() => void>(
    () => setIsSelectedMaterialsSidebarOpen(true),
    [setIsSelectedMaterialsSidebarOpen]
  );

  const handleSelectedMaterialsSidebarClose = useCallback<() => void>(
    () => setIsSelectedMaterialsSidebarOpen(false),
    [setIsSelectedMaterialsSidebarOpen]
  );

  const [isFiltersSidebarOpen, setIsFiltersSidebarOpen] = useState(true);

  const handleFiltersSidebarOpen = useCallback<() => void>(
    () => setIsFiltersSidebarOpen(true),
    [setIsFiltersSidebarOpen]
  );

  const handleFiltersSidebarClose = useCallback<() => void>(
    () => setIsFiltersSidebarOpen(false),
    [setIsFiltersSidebarOpen]
  );

  const selectedMaterialsCount = size(materialsSet?.selectedMaterials);

  return (
    <Fragment>
      {isFiltersSidebarOpen ? (
        <SelectMaterialsContentFiltersSidebar
          materialsFilters={materialsFilters}
          changeMaterialsFilters={handleChangeMaterialsFilters}
          onClose={handleFiltersSidebarClose}
        />
      ) : null}
      <MainScrollWrapper>
        <div className="flex-1 z-0">
          <div className="pt-6 pb-8">
            <div className="flex flex-col space-y-8">
              <SelectMaterialsContentHeader
                materialsFilters={materialsFilters}
                changeMaterialsFilters={handleChangeMaterialsFilters}
                showFiltersButton={!isFiltersSidebarOpen}
                onFiltersOpen={handleFiltersSidebarOpen}
                clearMaterialsFilters={clearMaterialsFiltersPersistInitial}
                filterMaterials={filterMaterials}
                sortMaterials={sortMaterials}
                currentSort={materialsSort as FetchMaterialsSort}
                materialsTotalCount={materialsTotalCount}
              />
              {materialsFetched && isEmpty(materials) ? (
                <NoResults
                  noResultsI18nText={words.thereAreNoMatchesForSelectedFilters}
                />
              ) : (
                <MaterialsList
                  materials={materials}
                  materialsFetched={materialsFetched}
                  materialsSetFetched={materialsSetFetched}
                  materialsError={materialsError}
                  materialsSetError={materialsSetError}
                  materialsIsPlaceholderData={materialsIsPlaceholderData}
                  materialsSetIsPlaceholderData={materialsSetIsPlaceholderData}
                  materialsTotalCount={materialsTotalCount}
                  materialsSet={materialsSet}
                  materialsCacheKeys={materialsCacheKeys}
                  materialsSetCacheKeys={materialsSetCacheKeys}
                  materialsFilters={materialsFilters}
                  changeMaterialsFilters={handleChangeMaterialsFilters}
                  onSelectedMaterialsSidebarOpen={
                    handleSelectedMaterialsSidebarOpen
                  }
                  onSelectedMaterialsSidebarClose={
                    handleSelectedMaterialsSidebarClose
                  }
                  updateMaterialsSetCache={updateMaterialsSetCache}
                  updateMaterialCache={updateMaterialCache}
                  onMaterialsSelect={onMaterialsSelect}
                  togglePreventModalClose={togglePreventModalClose}
                />
              )}
              {materialsSetFetched && materialsSet ? (
                <SelectedMaterialsFloatingCounter
                  materialsSet={materialsSet}
                  materialsSetCacheKey={materialsSetCacheKey}
                  isOpen={isSelectedMaterialsSidebarOpen}
                  onOpen={handleSelectedMaterialsSidebarOpen}
                  onClose={handleSelectedMaterialsSidebarClose}
                  updateMaterialsSetCache={updateMaterialsSetCache}
                  onMaterialsSelect={onMaterialsSelect}
                />
              ) : null}
              {hasNextMaterialsPage && (
                <LoadMoreButtonHelper
                  action={
                    MaterialsPermissions.READ_MATERIALS_LOAD_MORE_BUTTON_ITEMS_COUNT
                  }
                  itemsTotalCount={materialsTotalCount}
                  page={materialsPage}
                  limit={materialsLimit}
                  loadMoreItems={loadMoreMaterials}
                  itemsFetchingNextPage={materialsFetchingNextPage}
                />
              )}
            </div>
          </div>
        </div>
      </MainScrollWrapper>
      {isSelectedMaterialsSidebarOpen ? (
        <SelectMaterialsContentSidebar
          materialsSet={materialsSet}
          materialsSetFetched={materialsSetFetched}
          materialsSetError={materialsSetError}
          materialsSetIsPlaceholderData={materialsSetIsPlaceholderData}
          materialsCacheKeys={materialsCacheKeys}
          materialsSetCacheKeys={materialsSetCacheKeys}
          updateMaterialsSetCache={updateMaterialsSetCache}
          onClose={handleSelectedMaterialsSidebarClose}
          togglePreventModalClose={togglePreventModalClose}
          selectedMaterialsCount={selectedMaterialsCount}
        />
      ) : null}
    </Fragment>
  );
}

export default SelectMaterialsContent;
