import React, { useCallback, useState } from 'react';

import { SelectProductsModalButtonRequiredProps } from './SelectProductsModalButton.types';
import { ErrorMessage, I18nText } from '../../../types';
import { FetchProductsSetByUserIdScopeType } from '../../../main/productsSets/productsSetsTypes';

import {
  SelectProductsContent,
  SelectProductsContentOnProductsSelect
} from '../../../main/products/components/contents/SelectProductsContent';

import { SimpleModalButton } from '../../buttons/SimpleModalButton';

interface SelectProductsModalButtonBaseProps {
  value: string[];
  disabled?: boolean;
  submitErrorMessage?: ErrorMessage;
  resetOnClose?: boolean;
  productsSetScope?: FetchProductsSetByUserIdScopeType;
}

interface SelectProductsModalButtonWithTextProps {
  buttonText: string;
  i18nText?: never;
}

interface SelectProductsModalButtonWithI18nTextProps {
  buttonText?: never;
  i18nText: I18nText;
}

interface SelectProductsModalButtonWithOnSubmitProps {
  onSubmit: (value: string[]) => Promise<unknown>;
  onChange?: never;
}

interface SelectProductsModalButtonWithOnChangeProps {
  onSubmit?: never;
  onChange: (value: string[]) => void;
}

type SelectProductsModalButtonProps = SelectProductsModalButtonBaseProps &
  (
    | SelectProductsModalButtonWithTextProps
    | SelectProductsModalButtonWithI18nTextProps
  ) &
  (
    | SelectProductsModalButtonWithOnChangeProps
    | SelectProductsModalButtonWithOnSubmitProps
  );

function SelectProductsModalButton({
  value,
  disabled,
  onChange,
  onSubmit,
  className,
  i18nTitle,
  icon,
  iconClassName,
  modalIcon,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  i18nText,
  buttonText,
  resetOnClose,
  withoutInitialFocus,
  productsSetScope
}: SelectProductsModalButtonProps & SelectProductsModalButtonRequiredProps) {
  const [selectedProductIdsBeforeOpen, setSelectedProductIdsBeforeOpen] =
    useState<string[]>(value);
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>(value);
  const [imageLightboxOpen, setImageLightboxOpen] = useState<boolean>(false);

  const handleProductsSelect =
    useCallback<SelectProductsContentOnProductsSelect>(
      (selectedProducts) =>
        setSelectedProductIds(
          selectedProducts.map(
            (selectedProduct) => selectedProduct.id as string
          )
        ),
      [setSelectedProductIds]
    );

  const handleOpen = useCallback<() => void>(
    () => setSelectedProductIdsBeforeOpen(selectedProductIds),
    [selectedProductIds, setSelectedProductIdsBeforeOpen]
  );

  const handleCancel = useCallback<() => void>(
    () => setSelectedProductIds(selectedProductIdsBeforeOpen),
    [selectedProductIdsBeforeOpen, setSelectedProductIds]
  );

  const handleAfterSubmit = useCallback<() => void>(() => {
    if (resetOnClose) {
      setSelectedProductIdsBeforeOpen([]);
    } else {
      setSelectedProductIdsBeforeOpen(selectedProductIds);
    }
  }, [resetOnClose, selectedProductIds]);

  const handleSubmit = useCallback<() => Promise<void>>(async () => {
    if (onChange) {
      onChange(selectedProductIds);
      handleAfterSubmit();
    } else {
      return onSubmit?.(selectedProductIds).then(() => handleAfterSubmit());
    }
  }, [onChange, selectedProductIds, handleAfterSubmit, onSubmit]);

  const togglePreventModalClose = useCallback<() => void>(
    () => setImageLightboxOpen((prevState) => !prevState),
    [setImageLightboxOpen]
  );

  return (
    <SimpleModalButton
      className={className}
      childrenClassName="flex-1 flex overflow-hidden"
      i18nTitle={i18nTitle}
      icon={icon}
      iconClassName={iconClassName}
      modalIcon={modalIcon}
      modalSize="full"
      {...(i18nText ? { i18nText } : { buttonText })}
      i18nSubmitText={i18nSubmitText}
      disabled={disabled}
      isLoading={disabled}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      preventModalClose={imageLightboxOpen}
      onOpen={handleOpen}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      withoutInitialFocus={withoutInitialFocus}
    >
      <SelectProductsContent
        initialLimit={48}
        onProductsSelect={handleProductsSelect}
        togglePreventModalClose={togglePreventModalClose}
        productsSetScope={productsSetScope}
      />
    </SimpleModalButton>
  );
}

export default SelectProductsModalButton;
